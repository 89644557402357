import { Footer } from 'rsuite'; 

export default function BFooter() {
    return (
        <>
        <Footer className="footer">
            <ul>
                <li className="footermargin">Programmed by nine</li>
            </ul>
            <span className="smallFont">
            This database is not affiliated, associated, endorsed by, or in any way officially connected with Nihon Falcom. 
            </span>
        </Footer>
        </>
    )
}